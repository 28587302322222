'use client'
import React, {ReactNode, useState, useRef, Fragment} from 'react'
import {Transition, Portal} from '@headlessui/react'
import {
  useFloating,
  autoUpdate,
  offset,
  autoPlacement,
  shift,
  useDismiss,
  useRole,
  useInteractions,
} from '@floating-ui/react'

import type {Placement} from '@floating-ui/react'

/**
 * We extend the placement type to support 'auto' as 'auto' placement
 * is not included in @floating-ui unlike react-popper
 */
export type TooltipPlacement = Placement | 'auto'

type TooltipProps = {
  text: ReactNode
  offset?: number | number[]
  placement?: TooltipPlacement
  children: ReactNode
  width?: number
  maxWidth?: number
  className?: string
}

const Tooltip: React.FC<TooltipProps> = ({
  text,
  offset: offsetValue = 10,
  placement = 'auto',
  width = 80,
  maxWidth = 192,
  children,
  className,
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const tooltipGroupRef = useRef<HTMLSpanElement>(null)

  /**
   * Convert offset to the format expected by @floating-ui
   */
  const offsetMiddleware = Array.isArray(offsetValue)
    ? {mainAxis: offsetValue[1], crossAxis: offsetValue[0]}
    : {mainAxis: offsetValue, crossAxis: offsetValue}

  /**
   * Use the larger of width or maxWidth to prevent width > maxWidth issues
   */
  const effectiveMaxWidth = Math.max(width, maxWidth)

  /**
   * Use different @floating-ui middlewares to achieve the similar look/behaviour as in popper-js version
   */

  const {refs, floatingStyles, context} = useFloating({
    open: isTooltipVisible,
    onOpenChange: setIsTooltipVisible,
    ...(placement !== 'auto' && {placement}),
    middleware: [
      offset(offsetMiddleware),
      ...(placement === 'auto' ? [autoPlacement()] : []), // this is to replicate "auto" placement from popper.js
      shift(),
    ],
    whileElementsMounted: autoUpdate,
    strategy: 'fixed',
  })

  const dismiss = useDismiss(context)
  const role = useRole(context, {role: 'tooltip'})

  const {getReferenceProps, getFloatingProps} = useInteractions([dismiss, role])

  const handleMouseEnter = () => {
    setIsTooltipVisible(true)
  }

  const handleMouseLeave = () => {
    setTimeout(() => {
      // Do not close if user is hovering on tooltip (allow for links in tooltips)
      if (!tooltipGroupRef.current?.querySelector('*:hover')) {
        setIsTooltipVisible(false)
      }
    }, 250)
  }

  return (
    <span
      ref={tooltipGroupRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={className}
    >
      <div
        ref={refs.setReference}
        {...getReferenceProps()}
        className="cursor-pointer inline-block"
      >
        {children}
      </div>

      <Portal>
        <div
          ref={refs.setFloating}
          style={{
            ...floatingStyles,
            position: 'fixed',
            minWidth: `${width}px`,
            maxWidth: `${effectiveMaxWidth}px`,
            visibility: isTooltipVisible ? 'visible' : 'hidden',
            zIndex: 9999,
          }}
          {...getFloatingProps()}
          className="tooltippy bg-content-0 shadow-md text-content-80 text-caption-regular px-3 py-1.5 rounded tooltip w-fit"
        >
          <Transition
            show={isTooltipVisible}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="break-words whitespace-normal"
              style={{
                maxWidth: `${effectiveMaxWidth}px`,
              }}
            >
              {text}
            </div>
          </Transition>
        </div>
      </Portal>
    </span>
  )
}

export default Tooltip
